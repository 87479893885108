import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './mark.svg'; // Adjust the path if your file is in a subdirectory



const Header = () => {
const [isNavOpen, setIsNavOpen] = useState(false);

const toggleNav = () => {
  setIsNavOpen(!isNavOpen);
};

 const closeNav = () => {
	setIsNavOpen(false);
  };




return (
  <header className={`App-header ${isNavOpen ? 'nav-open' : ''}`}>
	  <div className="App-logo-container">
		<Link to="./">
		<img src={logo} className="App-logo" alt="logo" />
	  </Link>
	  </div>
	  {/* Hamburger icon */}
	  <div className={`hamburger ${isNavOpen ? 'active' : ''}`} onClick={toggleNav}>
		<div></div>
		<div></div>
		<div></div>
	  </div>
	  <nav className={`App-nav ${isNavOpen ? 'show' : 'hidden'}`}>
		<div className="nav-font">
		  <Link to="./" onClick={closeNav}>HOME</Link>
		  <Link to="./media-kit" onClick={closeNav}>MEDIA KIT</Link>
		  <Link to="./pitch-deck" onClick={closeNav}>PITCH DECK</Link>
		  <Link to="./about" onClick={closeNav}>ABOUT US</Link>
		</div>
	  </nav>
	  <div className="App-signin-container">
		<a href="https://my.baristabot.app" target="_blank" rel="noopener noreferrer">
		<button className="button">LOG IN</button>
		</a>
	  </div>
	</header>
  );
};

  export default Header;
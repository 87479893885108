import React from 'react';
import './responsive.css';
import Slide1 from './img/pitch-deck/001.jpeg';
import Slide2 from './img/pitch-deck/002.jpeg';
import Slide3 from './img/pitch-deck/003.jpeg';
import Slide4 from './img/pitch-deck/004.jpeg';
import Slide5 from './img/pitch-deck/005.jpeg';
import Slide7 from './img/pitch-deck/007.jpeg';
import Slide8 from './img/pitch-deck/008.jpeg';
import Slide9 from './img/pitch-deck/009.jpeg';
import Slide10 from './img/pitch-deck/010.jpeg';
import Slide11 from './img/pitch-deck/011.jpeg';
import Slide12 from './img/pitch-deck/012.jpeg';
import Slide13 from './img/pitch-deck/013.jpeg';
import Slide14 from './img/pitch-deck/014.jpeg';
import Slide15 from './img/pitch-deck/015.jpeg';
import Slide16 from './img/pitch-deck/016.jpeg';
import Slide17 from './img/pitch-deck/017.jpeg';
import Slide18 from './img/pitch-deck/018.jpeg';
import Slide19 from './img/pitch-deck/019.jpeg';
import Slide20 from './img/pitch-deck/020.jpeg';
import Slide21 from './img/pitch-deck/021.jpeg';
import Slide22 from './img/pitch-deck/022.jpeg';
import Slide23 from './img/pitch-deck/023.jpeg';
import Slide24 from './img/pitch-deck/024.jpeg';
import Slide25 from './img/pitch-deck/025.jpeg';
import Slide26 from './img/pitch-deck/026.jpeg';
import Slide27 from './img/pitch-deck/027.jpeg';
import Slide28 from './img/pitch-deck/028.jpeg';
import Slide29 from './img/pitch-deck/029.jpeg';
import Slide30 from './img/pitch-deck/030.jpeg';
import Slide31 from './img/pitch-deck/031.jpeg';
import Slide32 from './img/pitch-deck/032.jpeg';
import Slide33 from './img/pitch-deck/033.jpeg';
import Slide34 from './img/pitch-deck/034.jpeg';
import Footer from './Footer';



function PitchDeck() {
  return (
	<main>
	  <DefaultSection />
	</main>
  );
}

const DefaultSection = () => {
	return (
	<>
	<section className="default-section">
	  <div className="default-content">
		{/* Headline and subheading */}
		<h1>Pitch Deck</h1>
		
		<a href="https://www.dropbox.com/scl/fi/211ohull8zxqacqcri80j/Barista-Bot_VC_2024_2_23.pdf?rlkey=lp6gl64qqif75xoich366n24c&dl=0" target="_blank" rel="noopener noreferrer" download="Barista-Bot_VC_2024_2_23.pdf" className="mk-button">Download PDF</a>
		
	
		 
	  </div>
	
	  <div className="slides-content">
	  <img src={Slide1} alt="Title Slide" className="slides" />
	  <img src={Slide2} alt="01 Problem & Solution" className="slides" />
	  <img src={Slide3} alt="App Fatigue" className="slides" />
	  <img src={Slide4} alt="Dock with Most Used Apps" className="slides" />
	  <img src={Slide5} alt="Web" className="slides" />
	  <img src={Slide7} alt="Problem - Operational Bloat" className="slides" />
	  <img src={Slide8} alt="Solution - Increased Efficiency" className="slides" />
	  <img src={Slide9} alt="02 - Market & Fit" className="slides" />
	  <img src={Slide10} alt="Target Markets" className="slides" />
	  <img src={Slide11} alt="Coffee Shop Stats" className="slides" />
	  <img src={Slide12} alt="03 Technology" className="slides" />
	  <img src={Slide13} alt="AI Middleware" className="slides" />
	  <img src={Slide14} alt="Integrations" className="slides" />
	  <img src={Slide15} alt="04 Pricing Model" className="slides" />
	  <img src={Slide16} alt="SAAS Model & Unit Cost" className="slides" />
	  <img src={Slide17} alt="05 Team" className="slides" />
	  <img src={Slide18} alt="Sam DuRegger Bento Box" className="slides" />
	  <img src={Slide19} alt="06 Milestones" className="slides" />
	  <img src={Slide20} alt="Milestones - Seed Round" className="slides" />
	  <img src={Slide21} alt="07 Competitive Set" className="slides" />
	  <img src={Slide22} alt="Close Competitors" className="slides" />
	  <img src={Slide23} alt="Indirect Competitors" className="slides" />
	  <img src={Slide24} alt="08 Pilot Location" className="slides" />
	  <img src={Slide25} alt="tmrw.coffee" className="slides" />
	  <img src={Slide26} alt="Appendix" className="slides" />
	  <img src={Slide27} alt="09 Order Demo" className="slides" />
	  <img src={Slide28} alt="First Order" className="slides" />
	  <img src={Slide29} alt="Confirm Payment" className="slides" />
	  <img src={Slide30} alt="Create Emoji" className="slides" />
	  <img src={Slide31} alt="Emoji Order" className="slides" />
	  <img src={Slide32} alt="10 Sign-Up Demo" className="slides" />
	  <a href="https://www.figma.com/file/Sr8CXpPXAJaObIIR57Kvzw/Barista-Bot-Design---Handoff?type=design&node-id=52%3A3504&mode=design&t=aaRGIXNoxlGH68h8-1" target="_blank" rel="noopener noreferrer"><img src={Slide33} alt="Link to Prototype" className="slides" /></a>
	  <img src={Slide34} alt="\End" className="slides" />
	  <br /><br /><a href="https://www.dropbox.com/scl/fi/d88qx95ecqo01nl41o2on/Barista_Bot_Exec_Summary.pdf?rlkey=6wdnwhix6fz5exf2q1nhet7ux&dl=0" target="_blank" rel="noopener noreferrer" download="Barista_Bot_ExecSummary.pdf" className="mk-button">Download Executive Summary</a>
	  </div><br /><br />

	</section>
	<Footer />;
	</>
  );
};




export default PitchDeck;
import React from 'react';
import { Link } from "react-router-dom";
import BaristaBot from './img/barista-bot.svg';
import GreatTeam from './img/great-team.svg';


const SmallFooter = () => {
	return (
	  <footer className="small-footer">
		<div className="small-footer-content">
		  {/* Place your image here, for example, using an img tag */}
		  <div className="small-footer-bot">
		  <img src={GreatTeam} alt="Bot" className="small-footer-bot" />
		  </div>
		</div>
		<img src={BaristaBot} alt="Barista Bot" className="small-footer-baristabot" />
		<div className="sf-social-media-buttons">
		  {/* Replace href values with your actual links */}
		  <a href="https://instagram.com/baristabot_app" target="_blank" rel="noopener noreferrer" className="sf-social-button instagram">Instagram</a>
			<a href="https://x.com/baristabot_app" target="_blank" rel="noopener noreferrer" className="sf-social-button twitter">Twitter/X</a>
			<a href="https://notes.baristabot.app" target="_blank" rel="noopener noreferrer" className="sf-social-button substack">Substack</a>
		</div>
		<div className="small-footer-copy">
		  <p>© 2024 - Barista Bot, a division of Espresso + Milk, LLC</p>
		  <p><Link to="/terms-of-use">Terms of Service</Link>  |  <Link to="/privacy-policy">Privacy Policy</Link> | <a href="https://my.baristabot.app" target="_blank" rel="noopener noreferrer">Log In</a></p>
		</div>
	  </footer>
	);
  };


export default SmallFooter;
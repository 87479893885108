import React from 'react';
import { Link } from "react-router-dom";



const NoFooter = () => {
	return (
 <footer className="no-footer">
		<div className="no-footer-copy">
		  <p>© 2024 - Barista Bot, a division of Espresso + Milk, LLC</p>
		  <p><Link to="/terms-of-use">Terms of Service</Link>  |  <Link to="/privacy-policy">Privacy Policy</Link> | <a href="https://my.baristabot.app" target="_blank" rel="noopener noreferrer">Log In</a></p>
		</div>
	  </footer>
	);
  };


export default NoFooter;
import React, { useState, useEffect } from 'react';
import './App.css';
import './responsive.css';
import SmallFooter from './SmallFooter';
import QRTMRW from './img/QR_Contact_Card_tmrw.png';
import SamBento from './img/Sam_DuRegger_Bento.png';
import SamDuRegger from './img/Sam_DuRegger.png';


function AboutUs() {
  return (
	  <main>
		<AboutSection />
		<TeamSection />
		<ContactSection />
	  </main>
	);
  }
  
  const AboutSection = () => {
	  return (
	  <section className="about-section">
		<div className="about-content">
		  {/* Headline and subheading */}
		  <h1>About Us</h1>
		  <p>We are building the future of ordering and will be hiring as we hit our funding milestones. Please join our talent community to receive updates on new opportunities.</p>
		  <a href="mailto:hi@baristabot.app"><button className="mk-button">Join Talent Community</button></a>
	 </div> 

	  </section>
	);
  };

const TeamSection = () => {
	const [bentoImage, setBentoImage] = useState(
	  window.innerWidth > 768 ? SamBento : SamDuRegger
	);
  
	useEffect(() => {
	  const handleResize = () => {
		setBentoImage(window.innerWidth > 768 ? SamBento : SamDuRegger);
	  };
  
	  window.addEventListener('resize', handleResize);
  
	  // Clean up the event listener when the component unmounts
	  return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	return (
  <section className="team">
	  <div className="team-content">
	  <h1>Team</h1>
	  <p>Sam DuRegger, the founder of Barista Bot, has 18+ years of experience in digital innovation as the Product Lead at YouVersion in 2008, which now boasts over 600 MM users, to growing an innovation team at Sonic Drive-In from a small team of three to multiple teams of developers, project managers, designers, and product managers. He has led Digital Transformation initiatives in the Fast Casual Restaurant, Government, and Banking sectors, helping transition teams, organizations, and agencies toward a more digital-centric process orientation.</p>
	  </div>
	  <div className="bento">
	  <img src={bentoImage} alt="Sam DuRegger Bento Box" className="bento-image" />
	  </div>
	   <h3>Get in Touch</h3>
		<div className="team-contact-container">
				<div className="team-info-container">
				  <p>Are you a Seed Investor, Angel Investor, or VC looking to expand your portfolio? Schedule a  meeting over coffee with our founder to learn more about Barista Bot’s roadmap and capital structure.</p>
				</div>
				<div className="team-button-container">
				<a href="https://calendly.com/barista-bot" target="_blank" rel="noopener noreferrer" ><button className="char-button-stacked">BOOK VIA CALENDLY</button></a>
				  <a href="mailto:sam@beginthework.com" ><button className="char-button-stacked">EMAIL</button></a>
				</div>
			  </div>
  </section>
		  );
		};



const ContactSection = () => {
	  return (
		  <>
		<section className="contact-section">
		  <div className="contact-content">
			<h1>Now Available at tmrw.coffee</h1>
			<p>Barista Bot is now available at tmrw.coffee in Oklahoma City! We'd love for you to join other pilot users as we refine our conversational text-ordering process and code. Please scan the QR code add tmrw.coffee to your contacts, then text "hi" to 405-363-1989.</p>
			<a href="https://tmrw.coffee" target="_blank" rel="noopener noreferrer" ><button className="submit-button">TMRW.COFFEE ↗</button></a>
		  </div>
		  <div className="contact-form">
			<img src={QRTMRW} alt="QR Code Sign-up" className="QR-image" />
		  </div>
		</section>
		<SmallFooter />
		</>
	  );
	};


export default AboutUs;
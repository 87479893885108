import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import './App.css';
import './emoji/EmojiConversations.css'
import './responsive.css';
import TermsOfService from './terms-of-use.js';
import PrivacyPolicy from './privacy-policy.js';
import HomePage from './HomePage'; // Adjust the path as necessary based on your file structure
import Header from './Header';
import MediaKit from './media-kit';
import PitchDeck from './pitch-deck';
import AboutUs from './about';
import CoffeeShop from './coffee-shop';
import './Font.css'; 
import NotFound from './NotFound'; 



  
function App() {
  return (
    <Router>
      <ScrollToTop>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms-of-use" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/media-kit" element={<MediaKit />} />
          <Route path="/pitch-deck" element={<PitchDeck />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/coffee-shop" element={<CoffeeShop />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      </ScrollToTop>
    </Router>
  );
}



export default App;
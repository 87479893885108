import React from 'react';
import E3 from './img/E3-logo.png';
import Bridge2 from './img/Bridge2-logo.png';
import i2E from './img/i2E-logo.png';
import PlainsVentures from './img/plains-ventures-logo.png';
import SamDuRegger from './img/sam-duregger.svg';
import EmojiConversations from './EmojiConversations.jsx';
import featureImage from './img/wink-bot.svg';
import featureImageOne from './img/Square_POS.png';
import featureImageTwo from './img/DROPS.png';
import { Link } from 'react-router-dom';
import QRTMRW from './img/QR_Contact_Card_tmrw.png';
import Footer from './Footer';




const HomePage = () => {
  return (
	<main>
	  <HeroSection />
	  <FeaturesSection />
	  <FundingSection />
	  <ContactSection />
	</main>
  );
};

const HeroSection = () => (
  <section className="hero">
	<h1>Text your <span role="img" aria-label="coffee">☕️</span> order like you text a friend.</h1>
	<p>Add an emoji to your favorite order to fast-track your next order!</p>
	<EmojiConversations />
  </section>
);
	
	const FeaturesSection = () => (
	  <section className="features-section">
		<div className="features-header">
		  <h1>For Coffee Shops - Barista Bot <u>automates</u> orders and payments so Baristas can focus on quality drinks and excellent customer service.</h1>
		  <div className="header-image-container">
			<img src={featureImage} alt="Feature" className="feature-image" />
		  </div>
		</div>
	  <div className="features-body">
			<div className="feature-item1">
			  <h4>SQUARE POS</h4> {/* Assuming you want an h4 based on your CSS */}
			  <h3>Integrated order and payment.</h3>
			  <p>Our first integration is with the Square point-of-sale (POS) platform. Fully integrated into Order Ahead with no reporting or workflow changes for your team. It looks and acts like a mobile order!</p>
			  {/* Assuming featureImageOne import */}
			  <img src={featureImageOne} alt="Drops Feature" className="body-feature-image" />
			</div>
			<div className="feature-item2">
			  <h4>DROPS</h4> {/* Similar assumption for h4 */}
			  <h3>Coming Soon!</h3>
			  <p>Send a text with a picture, tasting notes, and a detailed description of your newly released coffee! Customers reply “yes” (or “yeah,” or “siiiick,” or “#%*@ yes,”), and we'll charge their card on file. There are no promo codes or links to the website. Everything happens in the text.</p>
			  {/* Assuming featureImageTwo import */}
			  <img src={featureImageTwo} alt="Specials Feature" className="body-feature-image" />
			</div>
		  </div>
		</section>
	  );
	
	
	
	const FundingSection = () => {
		return (
		<section className="funding">
			<h1>Funding</h1>
			<p>Barista Bot is an AI-powered, text-based ordering system that seamlessly integrates with point-of-sale systems, enabling efficient and personalized customer ordering experiences. By leveraging machine learning and natural language processing, Barista Bot streamlines the ordering, increases customer satisfaction, and drives revenue growth. With its scalable technology and focus on customer convenience, Barista Bot is well-positioned to revolutionize the coffee shop industry and expand into the broader quick-service and fast-casual restaurant market.</p>
			
			<p>Espresso + Milk, LLC is currently seeking a small round of funding after launching the Barista Bot pilot with pre-seed financing from i2E & Plains Ventures.</p>
			<Link to="./pitch-deck"><button className="char-button">PITCH DECK</button></Link>
			
			<div className="partner-boxes">
			  <div className="partner-box1">
				<span>AUGUST 2023</span>
				<img src={E3} alt="E3" className="funding-image1" />
				<p>Completed i2E’s E3’s micro-accelerator program.</p>
			  </div>
			  <div className="partner-box1">
				<span>January 2024</span>
				<img src={Bridge2} alt="Bridge2" className="funding-image2" />
				<p>Completed i2E’s inaugural pre-accelerator Bridge2.</p>
			  </div>
			  <div className="partner-box2">
				<span>Venture Partner</span>
				<img src={i2E} alt="i2E" className="funding-image3" />
				<p>Funded by Accelerate Oklahoma, a funding arm of OCAST and i2E.</p>
			  </div>
			  <div className="partner-box2">
				<span>Venture Partner</span>
				<img src={PlainsVentures} alt="Plains Ventures" className="funding-image4" />
				<p>Funded by Oklahoma Seed Fund, a Plains Venture Fund.</p>
			  </div>
			  {/* Add the other two boxes in a similar fashion */}
			</div>
			 <h3>Get in Touch</h3>
			  <div className="funding-contact-container">
					  
					  <div className="funding-image-container">
						<img src={SamDuRegger} alt="Sam DuRegger" className="funding-image" />
					  </div>
					  <div className="funding-info-container">
						<p>Are you a Seed Investor, Angel Investor, or VC looking to expand your portfolio? Schedule a  meeting over coffee with our founder to learn more about Barista Bot’s roadmap and capital structure.</p>
					  </div>
					  <div className="funding-button-container">
					  <a href="https://calendly.com/barista-bot" target="_blank" rel="noopener noreferrer" ><button className="char-button-stacked">BOOK VIA CALENDLY</button></a>
						<a href="mailto:sam@beginthework.com" ><button className="char-button-stacked">EMAIL</button></a>
					  </div>
					</div>
		</section>
				);
			  };
	
	const ContactSection = () => {
				return (
					<>
				  <section className="contact-section">
					<div className="contact-content">
					  <h1>Now Available at tmrw.coffee</h1>
					  <p>Barista Bot is now available at tmrw.coffee in Oklahoma City! We'd love for you to join other pilot users as we refine our conversational text-ordering process and code. Please scan the QR code add tmrw.coffee to your contacts, then text "hi" to 405-363-1989.</p>
					  <a href="https://tmrw.coffee" target="_blank" rel="noopener noreferrer" ><button className="submit-button">TMRW.COFFEE ↗</button></a>
					</div>
					<div className="contact-form">
					  <img src={QRTMRW} alt="QR Code Sign-up" className="QR-image" />
					</div>
				  </section>
				  <Footer />;
				  </>
				);
			  };



export default HomePage;



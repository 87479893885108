import React from 'react';
import './App.css';
import './responsive.css';

function CoffeeShop() {
  return (
	  <main>
		<DefaultSection />
	  </main>
	);
  }
  
  const DefaultSection = () => {
	  return (
	  <section className="default-section">
		<div className="default-content">
		  {/* Headline and subheading */}
		  <h1>Are you a coffee shop?</h1>
		  {/* The Termly content will be injected into .default-content */}
		</div>
	  </section>
	);
  };


export default CoffeeShop;
import React, { useEffect } from 'react';
import './App.css';
import './responsive.css';
import SmallFooter from './SmallFooter';

function PrivacyPolicy() {
  return (
	<main>
	  <DefaultSection />
	</main>
  );
}

const DefaultSection = () => {
  useEffect(() => {
	// Check if the script is already added
	const existingScript = document.getElementById('termly-jssdk');
	if (!existingScript) {
	  const script = document.createElement('script');
	  script.src = "https://app.termly.io/embed-policy.min.js";
	  script.id = 'termly-jssdk';
	  document.body.appendChild(script);
	}

	// Create the div container if it doesn't exist and append it where you need
	const termlyDiv = document.createElement('div');
	termlyDiv.setAttribute('name', 'termly-embed');
	termlyDiv.setAttribute('data-id', 'ece075af-2578-448a-a356-a18f8e90f942');

	// Append the div to the section or a specific element within your component
	document.querySelector('.default-content').appendChild(termlyDiv); // Adjust this to place the div where you want

	// Clean up the script and div when the component is unmounted
	return () => {
	  if (existingScript) {
		existingScript.remove();
	  }

	  if (termlyDiv) {
		termlyDiv.remove();
	  }
	};
  }, []);

  return (
	  <>
	<section className="default-section">
	  <div className="default-content">
		{/* Headline and subheading */}
		<h1>Privacy Policy</h1>
		{/* The Termly content will be injected into .default-content */}
	  </div>
	</section>
	<SmallFooter />
	</>
  );
};

export default PrivacyPolicy;

import React, { useState } from 'react';

const EmojiConversation = () => {
	const conversations = [
		[
			{ text: "I’d love an oat milk cappuccino this morning.", class: "bubble_r" },
			{ text: "Great pick! 10oz cappuccino with oat milk! Would you like anything else?", class: "bubble_l" },
			{ text: "Nope", class: "bubble_r" },
			{ text: "Fantastic. Your total is $5.43. Would you like to charge your card on file?", class: "bubble_l" },
			{ text: "Yes", class: "bubble_r" },
			{ text: "Confirmed. We’ll have it ready in 7-10 minutes. See you soon.", class: "bubble_l" }
		],
		[
			{ text: "Can I get a cold brew matcha with oat milk?", class: "bubble_r" },
			{ text: "Yum. What size? 12oz or 16oz?", class: "bubble_l" },
			{ text: "16oz", class: "bubble_r" },
			{ text: "Got it. 16oz, Cold Brew Matcha with oat milk. Use same payment on file?", class: "bubble_l" },
			{ text: "👍🏼", class: "bubble_r" },
			{ text: "Thanks! We’ve got your order and will have it ready in 7-10 minutes. See you soon.", class: "bubble_l" }
		],
		[
			{ text: "I’d love a croissant and a drip coffee.", class: "bubble_r" },
			{ text: "Sounds good. What size drip? 8oz, 12oz, or 16oz?", class: "bubble_l" },
			{ text: "12oz", class: "bubble_r" },
			{ text: "Cool. We’ve got your drip coffee and a croissant, charge the card on file?", class: "bubble_l" },
			{ text: "Sure", class: "bubble_r" },
			{ text: "Great! Your drink will be ready in 7-10 minutes. See you soon.", class: "bubble_l" }
		],
		[
			{ text: "🦄", class: "bubble_r" },
			{ text: "Your shortcut order is set to: 1 x 16oz quad caramel iced latte with almond milk. Confirm to charge card on file?", class: "bubble_l" },
			{ text: "Yep", class: "bubble_r" },
			{ text: "Confirmed. We’ll have this ready in 7-10 minutes. See you soon.", class: "bubble_l" },
			{ text: "Your drink is ready for pickup.", class: "bubble_l"}
		],
	];

	const [conversation, setConversation] = useState(conversations[0]);
		const [activeIndex, setActiveIndex] = useState(0);  // State to track the active emoji index
		const [isTransitioning, setIsTransitioning] = useState(false); // Flag to manage conversation transitions
	
		const handleEmojiClick = (index) => {
			if (activeIndex === index) return; // Do nothing if the current conversation is clicked again
	
			setIsTransitioning(true);  // Start the transition
			setActiveIndex(index);  // Update the active index
	
			setTimeout(() => {
				setConversation([]);  // Clear the current conversation to reset the animation
				setTimeout(() => {
					setConversation(conversations[index]);  // Set the new conversation
					setIsTransitioning(false);  // End the transition
				}, 300);  // Time for the conversation to fade out before loading new content
			}, 10);  // Minimal delay to trigger re-render
		};
	
		return (
			<div className="conversation-container">
				<div className="emoji-gallery">
					<div className="emoji-selector">
						{conversations.map((_, idx) => (
							<div className={`emoji ${activeIndex === idx ? 'active' : ''}`} key={idx}>
								<span onClick={() => handleEmojiClick(idx)} role="img" aria-label={`emoji-${idx}`}>
									{['🧢', '🍵', '🥐', '🦄'][idx]}
								</span>
							</div>
						))}
					</div>
				</div>
				<div className={`chat-bubbles ${isTransitioning ? 'transitioning' : ''}`}>
					{conversation.map((message, index) => (
						<div key={index} id={`bubble${index + 1}`} className={message.class}>
							{message.text}
						</div>
					))}
				</div>
			</div>
		);
	};
	
	export default EmojiConversation;
import React from 'react';
import { Link } from 'react-router-dom';
import './responsive.css';
import NoFooter from './NoFooter';

const NotFound = () => {
  return (
	  <>
	<div className="not-found-container">
	  <h1>Page Not Found</h1>
	  <div className="chat-bubbles-404">
		<div className="bubble_r" id="bubble1">Hey! Can I see this page?</div>
		<div className="bubble_l" id="bubble2">Sorry, we can't find a page like that. ¯\_(ツ)_/¯</div>
		<div className="bubble_r" id="bubble3">What do you mean?</div>
		<div className="bubble_l" id="bubble4">We literally can't find it. Maybe try the homepage?</div>
		<div className="bubble_r" id="bubble5">Ok, Link?</div>
		<div className="go-home-link" id="bubble6"><Link to="/">GO TO HOMEPAGE</Link></div>
	  </div>
	</div>
	<NoFooter />
	</>
  );
};

export default NotFound;
import React from 'react';
import './App.css';
import './Font.css';
import './responsive.css';
import './MediaKit.css'; // Assuming you have a separate CSS file for the Media Kit
  // Import your images here
import BrandGuidelinesImage from './img/mk-brand-guidelines.png';
import LogosImage from './img/mk-logos.png';
import ScenesImage from './img/mk-scenes.jpg';
import TeamImage from './img/mk-team.png';
import PressReleasesImage from './img/mk-press-releases.jpg';
import UpdatesImage from './img/mk-updates.jpg';
import Footer from './Footer';

const MediaKit = () => {
  // You could store these as an array of objects if there are many, for brevity I'll keep them separate
  const downloadItems = [
	
	
	{
	  title: "Logos",
	  image: LogosImage,
	  link: "https://www.dropbox.com/scl/fo/6gwkane1icrr129b1luiv/h?rlkey=z4zz5rto896ijnhtfwddffpur&dl=0",
	  buttonText: "Download",
	},
	
	{
	  title: "Brand Guidelines",
	  image: BrandGuidelinesImage,
	  link: "https://www.dropbox.com/scl/fi/llteje2dt5aywyop5deta/BaristaBot_BrandGuidelines_1.1.pdf?rlkey=p4eanv9cvtxpd7qiwqfnveji3&dl=0",
	  buttonText: "Download PDF",
	},
	
	{
	  title: "Team",
	  image: TeamImage,
	  link: "/about",
	  buttonText: "About Us",
	},
	
	{
	  title: "Scenes",
	  image: ScenesImage,
	  link: "https://www.dropbox.com/scl/fo/l4qg8gqpgsw3ixojv8dys/h?rlkey=c5lobgspxty169mc3ht1fhnya&dl=0",
	  buttonText: "Download",
	},
	
	{
	  title: "Press Releases",
	  image: PressReleasesImage,
	  link: "https://notes.baristabot.app/t/press-release",
	  buttonText: "Substack",
	},
	
	{
	  title: "Updates",
	  image: UpdatesImage,
	  link: "https://notes.baristabot.app",
	  buttonText: "Substack",
	},
	
	
	
	// ... more items
  ];

  return (
	  <>
  <div className="media-kit-container">
	<h1>Media Kit</h1>
	<div className="download-items-container">
	  {downloadItems.map((item) => (
		<div className="download-item" key={item.title}>
		  <div
			className="download-image"
			style={{ backgroundImage: `url(${item.image})` }}
		  >
			<div className="download-image-content">
			  {/* Content overlayed on the image, if any */}
			</div>
		  </div>
		  <h4>{item.title}</h4>
		  <a href={item.link} className="mk-button" target="_blank"
			rel="noopener noreferrer">
			{item.buttonText}
		  </a>
		</div>
	  ))}
	  </div>
	</div>
	<Footer />;
	</>
  );
};

export default MediaKit;

 